import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { ComingSoon } from '../components/ComingSoon';
import { Main } from '../components/Main';
import { RichText, RichTextData } from '../components/RichText';

export type GenericQuery = {
  contentfulPageGeneric: {
    title: string;
    description?: string;
    content?: RichTextData;
  };
};

const Generic = ({ data }: PageProps<GenericQuery>): ReactElement => {
  const { title, content } = data.contentfulPageGeneric;

  return (
    <Main pageTitle={title} variant={'narrow'}>
      {!content ? <ComingSoon /> : <RichText data={content} />}
    </Main>
  );
};

export default Generic;

export const query = graphql`
  query ($id: String!) {
    contentfulPageGeneric(id: { eq: $id }) {
      ... on ContentfulPageGeneric {
        ...Generic
      }
    }
  }
`;
